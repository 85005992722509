import clsx from 'clsx'
import { ToastContainer as DefaultToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface ToastProps {
  className?: string
}

export default function ToastContainer(props: ToastProps) {
  return (
    <DefaultToastContainer
      transition={Slide}
      className={clsx(props.className)}
    />
  )
}
