import { NextUIProvider } from '@nextui-org/react'
import type { LinksFunction, MetaFunction } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { SupabaseProvider } from '~/contexts/supabaseContext'
import { UserContextProvider } from '~/contexts/userContext'

import { json } from '@remix-run/node'
import { FlagsProvider } from 'flagged'

import { isRouteErrorResponse, LoaderFunction } from 'react-router-dom'
import { ToastContainer } from '~/components/Toast'
import { SUPABASE_ANON_KEY, SUPABASE_URL } from '~/config/config.server'
import { DrillContextProvider } from '~/contexts/drillContext'
import { ServerContextProvider } from '~/contexts/serverContext'
import useStrings from '~/hooks/useStrings'
import { genreService } from '~/services/GenreService'
import { programService } from '~/services/ProgramService.server'
import UserService from '~/services/UserService.server'
import stylesheet from '~/tailwind.css?url'

export const meta: MetaFunction = () => [
  {
    title: 'My Piano Trainer',
  },
]

export const loader: LoaderFunction = async ({ request }) => {
  const userData = await new UserService().userData(request)
  const env = {
    SUPABASE_URL,
    SUPABASE_ANON_KEY,
  }

  return json({
    env,
    userData,
    genreTreeById: genreService.getGenreTreeById(),
    genreTreeOrdered: genreService.ordered(),
    programsById: programService.programs(),
  })
}

interface DocumentProps {
  children: React.ReactNode
  title?: string
}

const Document = ({ children, title }: DocumentProps) => {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link rel="stylesheet" href="/abcjs-audio.css" />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()
  const { errorBoundaryTitle, errorBoundaryHeader, errorBoundaryBody } =
    useStrings()

  console.log(`Route error: ${JSON.stringify(error)}`)
  if (isRouteErrorResponse(error)) {
    if (error.status === 403) {
      return <div>You do not have permission to view this page.</div>
    }
  }
  return (
    <html>
      <head>
        <title>{errorBoundaryTitle}</title>
        <Meta />
        <Links />
      </head>
      <body>
        <h1>{errorBoundaryHeader}</h1>
        <div>{errorBoundaryBody}</div>
        <Scripts />
      </body>
    </html>
  )
}

const queryClient = new QueryClient()

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
]

export default function App() {
  const { env, userData, genreTreeById, genreTreeOrdered, programsById } =
    useLoaderData<typeof loader>()

  const supabaseUrl = env.SUPABASE_URL
  const supabaseAnonKey = env.SUPABASE_ANON_KEY

  return (
    <Document>
      <SupabaseProvider supabaseUrl={supabaseUrl} supabaseKey={supabaseAnonKey}>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider userData={userData}>
            <ServerContextProvider
              genreTreeById={genreTreeById}
              genreTreeOrdered={genreTreeOrdered}
              programsById={programsById}
            >
              <DrillContextProvider>
                <FlagsProvider features={{ v1: userData?.isAdmin === true }}>
                  <NextUIProvider>
                    <ToastContainer />
                    <main className="dark text-foreground bg-background">
                      <Outlet />
                    </main>
                  </NextUIProvider>
                </FlagsProvider>
              </DrillContextProvider>
            </ServerContextProvider>
          </UserContextProvider>
        </QueryClientProvider>
      </SupabaseProvider>
    </Document>
  )
}
